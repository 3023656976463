import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import './services.css';
import NationalModal from './national-modal';
// Banners
import goldBanner from '../../../assets/imgs/landing-page/gold_service.jpg';
import twelveBanner from '../../../assets/imgs/landing-page/12_12.jpg';
import courierBanner from '../../../assets/imgs/landing-page/courier.jpg';
import perishableBanner from '../../../assets/imgs/landing-page/perishable.jpg';
import highValueBanner from '../../../assets/imgs/landing-page/high_value.jpg';
import highTechnologyBanner from '../../../assets/imgs/landing-page/high_technology.jpg';
import liveAnimalsBanner from '../../../assets/imgs/landing-page/live_animals.jpg';
import humansRemainsBanner from '../../../assets/imgs/landing-page/human_remains.jpg';
import pharmaxpsBanner from '../../../assets/imgs/landing-page/pharma_xps.jpg';
import pharmastdBanner from '../../../assets/imgs/landing-page/pharma_std.jpg';
import pouchBanner from '../../../assets/imgs/landing-page/pouch.jpg';
import dangerousMarketBanner from '../../../assets/imgs/landing-page/dangerous_market.jpg';
import industryServicesBanner from '../../../assets/imgs/landing-page/industry_services.jpg';
// Iconos
import goldIcon from '../../../assets/imgs/landing-page/gold_icon.svg';
import twelveIcon from '../../../assets/imgs/landing-page/12_12_icon.svg';
import courierIcon from '../../../assets/imgs/landing-page/mensajeria_icon.svg';
import perishableIcon from '../../../assets/imgs/landing-page/perecederos_icon.svg';
import highValueIcon from '../../../assets/imgs/landing-page/alto_valor_icon.svg';
import highTechnologyIcon from '../../../assets/imgs/landing-page/alta_tecnologia_icon.svg';
import liveAnimalsIcon from '../../../assets/imgs/landing-page/animales_vivos_icon.svg';
import humansRemainsIcon from '../../../assets/imgs/landing-page/restos_humanos_icon.svg';
import pharmaxpsstdIcon from '../../../assets/imgs/landing-page/pharma_xps_std_icon.svg';
import pouchIcon from '../../../assets/imgs/landing-page/valija_icon.svg';
import dangerousMarketIcon from '../../../assets/imgs/landing-page/mercancia_peligrosa_icon.svg';
import industryServicesIcon from '../../../assets/imgs/landing-page/servicios_industria_icon.svg';
import arrow from '../../../assets/imgs/landing-page/vector.svg';
// JSON's
import goldData from '../../../translations/es/products/nationals/gold.json';
import twelveData from '../../../translations/es/products/nationals/standar.json';
import courierData from '../../../translations/es/products/nationals/courier.json';
import perishabelData from '../../../translations/es/products/nationals/perishable-goods.json';
import highValueData from '../../../translations/es/products/nationals/high-value.json';
import highTechnologyData from '../../../translations/es/products/nationals/high-technology.json';
import liveAnimalsData from '../../../translations/es/products/nationals/live-animals.json';
import humansRemainsData from '../../../translations/es/products/nationals/human-remains.json';
import pharmaxpsData from '../../../translations/es/products/nationals/pharma-xps.json';
import pharmastdData from '../../../translations/es/products/nationals/pharma-std.json';
import pouchData from '../../../translations/es/products/nationals/pouch.json';
import dangerousMarketData from '../../../translations/es/products/nationals/hazardous-goods.json';
import industryServicesData from '../../../translations/es/products/nationals/industry-services.json';

function National() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const [apiData, setApiData] = useState(null);

  const cards = [
    { id: 1, title: "Gold", icon: goldIcon, image: goldBanner, description: "Servicio urgente, dedicado a embarques que son transportados en un vuelo específico, con prioridad en el abordaje y pronta entrega en el aeropuerto de destino.", data: goldData, idApi: 'a84034f2-01c6-417f-b05b-267a81f27c06' },
    { id: 2, title: "12+12", icon: twelveIcon, image: twelveBanner, description: `Servicio no urgente, cualquier tipo de mercancía "carga general" excepto mercancía clasificada, nuestro servicio 12+12 te ofrece el mejor precio y calidad en la entrega de tu envío al día siguiente.`, data: twelveData, idApi: '41bd6b29-f877-4cfe-b40e-38d216751e64' },
    { id: 3, title: "Mensajería", icon: courierIcon, image: courierBanner, description: "Servicio no urgente para embarques de hasta 3 Kg, cuyo contenido no requiera un manejo especial.", data: courierData, idApi: '407138e3-da91-4725-b44e-ce97858a0fc3' },
    { id: 4, title: "Perecederos", icon: perishableIcon, image: perishableBanner, description: `Servicio de mercancía clasificada. Su conservación depende de un manejo adecuado (cámaras de refrigeración "deberás de utilizar este servicio...) y tiempos rápidos de traslado.`, data: perishabelData, idApi: '158bdddf-e801-4157-9720-40346a29601e' },
    { id: 5, title: "Alto Valor", icon: highValueIcon, image: highValueBanner, description: "Servicio de mercancía clasificada.  Tus envíos más valiosos en todo momento se encuentran controlados, contando con protección especializada para embarques donde la seguridad es fundamental.", data: highValueData, idApi: 'c25cf9ca-9fcf-4b25-8db0-a9e7bb2bed53' },
    { id: 6, title: "Alta Tecnología", icon: highTechnologyIcon, image: highTechnologyBanner, description: "Servicio de mercancía clasificada. Ideal para transportar equipos y/o aparatos de alta tecnología, ofreciendo un manejo especial en todo momento.", data: highTechnologyData, idApi: '657ae65a-eb11-4666-b84d-3b3e1e953590' },
    { id: 7, title: "Animales Vivos", icon: liveAnimalsIcon, image: liveAnimalsBanner, description: "Servicio de mercancía clasificada. Enfocado para el traslado de animales y mascotas de forma rápida y segura, donde ellos son lo más importante para nosotros.", data: liveAnimalsData, idApi: '4c7bc6a5-2883-403f-9cf4-b1312507e7f3' },
    { id: 8, title: "Restos Humanos", icon: humansRemainsIcon, image: humansRemainsBanner, description: "Servicio de mercancía clasificada. Dirigido a las funerarias, transportando restos humanos o cenizas con una atención especializada y digna en esos momentos difíciles.", data: humansRemainsData, idApi: '6a1613dd-4bc4-4d29-b519-24eb5e068063' },
    { id: 9, title: "Pharma XPS", icon: pharmaxpsstdIcon, image: pharmaxpsBanner, description: "Servicio de mercancía clasificada. Dedicado al envío de vacunas y/o medicamentos  de manera urgente. Obtendrás alta prioridad al momento de la recepción.", data: pharmaxpsData, idApi: 'baded5a0-8456-4843-bdde-8e3e3abb27a9' },
    { id: 10, title: "Pharma STD", icon: pharmaxpsstdIcon, image: pharmastdBanner, description: "Servicio urgente, dedicado a embarques que son transportados en un vuelo específico, con prioridad en el abordaje y pronta entrega en el aeropuerto de destino.", data: pharmastdData, idApi: '49e9dc7b-4c41-4938-b800-ee5b5c7a5269' },
    { id: 11, title: "Valija", icon: pouchIcon, image: pouchBanner, description: "Servicio dedicado a empresas que manejan mensajería y paquetería en grandes volúmenes. Contarán con prioridad de abordaje y asignación de un vuelo específico.", data: pouchData, idApi: '2128300e-9022-4966-907f-e50f0fad2942' },
    { id: 12, title: "Mercancía Peligrosa", icon: dangerousMarketIcon, image: dangerousMarketBanner, description: "Servicio de mercancía clasificada. Dedicado al envío de productos o sustancias peligrosas. *Será importante cumplir con los requisitos de documentación y embalaje.", data: dangerousMarketData, idApi: 'd5593ad8-15cd-4168-90d9-27ed9b86cfb9' },
    { id: 13, title: "Servicios para la industria", icon: industryServicesIcon, image: industryServicesBanner, description: "Servicios especializados para empresas e industrias que requieren un servicio específico de acuerdo a sus necesidades. Contacta a un ejecutivo (número).", data: industryServicesData, idApi: '265e5380-2d8b-4a8c-a120-ffa9cd820e54' },
  ];

  const handleCardClick = async (card) => {
    setSelectedCard(card);
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/api/textWeb/${card.idApi}?language=1`, {
        headers: {
          Authorization: process.env.REACT_APP_TOKEN,
        },
      });
      setApiData(response.data.textWeb);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error al obtener datos del servicio:", error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedCard(null);
    setApiData(null); // Limpia los datos de la API cuando el modal se cierra
  };

  return (
    <div className='service-container'>
      <Swiper
        spaceBetween={30}
        slidesPerView="auto"
        loop={false} // Desactivar el bucle para evitar que vuelva al inicio
        resistanceRatio={0} // Evitar el efecto de resorte
      >
        {cards.map((card) => (
          <SwiperSlide key={card.id} style={{ width: "400px" }}>
            <div className="card" onClick={() => handleCardClick(card)}>
              <img src={card.image} alt={card.title} className="card-image" />
              <div className="card-content">
                <img src={card.icon} alt={`${card.title} icon`} className="card-icon mt-4" /> {/* Ícono como imagen */}
                <h4 className="card-title mt-4">{card.title}</h4>
                <p className="description-landing">{card.description}</p> {/* Descripción que se muestra en hover */}
                
              </div>
              <img src={arrow} className="arrow-icon mt-4" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <NationalModal isOpen={isModalOpen} onClose={handleCloseModal} cardData={selectedCard} jsonData={selectedCard ? selectedCard.data : null} apiData={apiData}  />
      
    </div>
  );
}

export default National;