import React from "react";
import ScrollToTop from "../../components/scrollToTop/scrollToTop";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/Footer";
import "./form-info.css"; // Importamos el archivo CSS
import { Helmet } from "react-helmet";

const FormInfo = () => {

    return(
        <>
            <Helmet>
                <title>Formulario de Contacto</title>
                <meta name="description" />
            </Helmet>
            <ScrollToTop />
            <Navbar />

            <div className="container-fluid container-form-info">
                <div className="row">
                    <div className="col">
                    <h1>Contacta con nosotros</h1>
                    <form
                        action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00D3k000000sqIL"
                        method="POST"
                    >
                        <input type="hidden" name="oid" value="00D3k000000sqIL" />
                        <input type="hidden" name="retURL" value="Inicio | Aeroméxico Cargo" />
                        
                        <label htmlFor="first_name">Nombre</label>
                        <input
                            id="first_name"
                            name="first_name"
                            type="text"
                            maxLength="40"
                            required
                        />

                        <label htmlFor="last_name">Apellidos</label>
                        <input
                            id="last_name"
                            name="last_name"
                            type="text"
                            maxLength="80"
                            required
                        />

                        <label htmlFor="email">Email</label>
                        <input
                            id="email"
                            name="email"
                            type="text"
                            maxLength="80"
                            required
                        />

                        <label htmlFor="mobile">Celular</label>
                        <input
                            id="mobile"
                            name="mobile"
                            type="text"
                            maxLength="40"
                            required
                        />

                        <label htmlFor="state">Estado/Provincia</label>
                        <input
                            id="state"
                            name="state"
                            type="text"
                            maxLength="20"
                            required
                        />

                        <input type="submit" name="submit" value="Enviar" />
                    </form>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}

export default FormInfo