import React, { useState } from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import './services.css';
import InternationalModal from './international-modal';
// Banners
import expressBanner from '../../../assets/imgs/landing-page/express_banner.jpg';
import generalBanner from '../../../assets/imgs/landing-page/general_banner.jpg';
import specializedBanner from '../../../assets/imgs/landing-page/specialized_banner.jpg';
import customizedBanner from '../../../assets/imgs/landing-page/customized_banner.jpg';
// Logos
import expressLogo from "../../../assets/imgs/landing-page/express_layout.svg";
import generalLogo from "../../../assets/imgs/landing-page/general_layout.svg";
import specializedLogo from "../../../assets/imgs/landing-page/specialized_layout.svg";
import customizedLogo from "../../../assets/imgs/landing-page/customized_layout.svg";

import arrow from '../../../assets/imgs/landing-page/vector.svg';

function International() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const [apiData, setApiData] = useState(null);

  const cards = [
    { id: 1, title: "Express", image: expressLogo, banner: expressBanner, description: `Carga Express pesando menos de 32 kg por pieza y máximo 100 kg por embarque.`, idApi: '1c52c4f5-068a-452b-9375-10110634d25b'  },
    { id: 2, title: "General", image: generalLogo, banner: generalBanner, description: `Nuestra opción para cualquier envío estándar que contenga mercancía que no requiere de un manejo especializado.`, idApi: 'c4f4cd6a-cc0f-48cc-812c-d4daff768ab5' },
    { id: 3, title: "Specialized", image: specializedLogo, banner: specializedBanner, description: "Cualquier tipo de carga a cualquier parte del mundo.", idApi: 'fa1b236b-b81e-47b0-87e8-960dde11a627' },
    { id: 4, title: "Customized", image: customizedLogo, banner: customizedBanner, description: "Customized es nuestra solución de carga totalmente personalizada.", idApi: '7818a77c-3634-4a44-b753-354c0d8f16bb' },
  ];

  const handleCardClick = async (card) => {
    setSelectedCard(card);
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/api/textWeb/${card.idApi}?language=1`, {
        headers: {
          Authorization: process.env.REACT_APP_TOKEN,
        },
      });
      setApiData(response.data.textWeb);
      setIsModalOpen(true); 
    } catch (error) {
      console.error("Error al obtener datos del servicio:", error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedCard(null);
    setApiData(null); // Limpia los datos de la API cuando el modal se cierra
  };

  return (
    <div className='service-container'>
      <Swiper
        spaceBetween={20}
        slidesPerView="auto"
        loop={false} // Desactivar el bucle para evitar que vuelva al inicio
        resistanceRatio={0} // Evitar el efecto de resorte
      >
        {cards.map((card) => (
          <SwiperSlide key={card.id} style={{ width: "400px" }}>
            <div className="card-international" onClick={() => handleCardClick(card)}>
              <img src={card.image} alt={card.title} className="card-international-image" />
              <div className="card-international-content">
                <h4 className="card-international-title mt-4">{card.title}</h4>
                <p className="description-international">{card.description}</p> {/* Descripción que se muestra en hover */}
              </div>
              <img src={arrow} className="arrow-international-icon mt-4" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <InternationalModal isOpen={isModalOpen} onClose={handleCloseModal} cardData={selectedCard} apiData={apiData} />
      
    </div>
  );
}

export default International;